import { TimezoneN } from "Everlaw/DateUtil";
import { AWSRegion } from "Everlaw/Server";

/**
 * Selected based on a guess of frequency/usefulness.
 * Feel free to add more.
 */
export const forProcessing = {
    "Pacific/Honolulu": "(GMT-10:00) Hawaii Time",
    "America/Anchorage": "(GMT-08:00) Alaska Time",
    "America/Los_Angeles": "(GMT-07:00) Pacific Time",
    "America/Denver": "(GMT-06:00) Mountain Time",
    "America/Phoenix": "(GMT-07:00) Mountain Time - Arizona",
    "America/Chicago": "(GMT-05:00) Central Time",
    "America/New_York": "(GMT-04:00) Eastern Time",
    "Europe/London": "(GMT+01:00) London",
    "Europe/Paris": "(GMT+02:00) Paris",
    "Europe/Helsinki": "(GMT+03:00) Helsinki",
    "Europe/Moscow": "(GMT+03:00) Moscow+00 - Moscow",
    "Australia/Perth": "(GMT+08:00) Western Time - Perth",
    "Asia/Tokyo": "(GMT+09:00) Tokyo",
    "Australia/Adelaide": "(GMT+09:30) Central Time - Adelaide",
    "Australia/Sydney": "(GMT+10:00) Eastern Time - Melbourne, Sydney",
};

/**
 * This list is definitely not set in stone, but here is the reasoning behind it:
 *
 * - Because of TimezoneSelectO (which includes both daylight & non-daylight time), a list of ~5
 * common timezones is really a list of ~10 common timezones. Having more than ~10 common timezones
 * is probably too much.
 * - While there are probably a fair number of "international" projects (e.g. US-based firms working on
 * UK data, AU-based firms working on US data, etc), which is an argument against choosing timezones
 * by region like this, it is a common enough idiom to have "your country's" timezones at the top of
 * the list. And if you're working on such an "international" project, it's not unreasonable to have to
 * simply type your time zone's name if it doesn't appear at the top of the list.
 */
export const forDateSearch = makeForDateSearch();
function makeForDateSearch() {
    // Intentionally excluding Hawaii-Aleutian, Alaska, and Arizona time zones, guessing that these are
    // less common.
    const usTimezones = [
        "UTC",
        "US/Pacific",
        "US/Mountain",
        "US/Central",
        "US/Eastern",
    ] as TimezoneN[];

    const auTimezones = [
        "UTC",
        "Australia/West",
        "Australia/North",
        "Australia/South",
        "Australia/Sydney",
    ] as TimezoneN[];

    const euTimezones = ["UTC", "Europe/London"] as TimezoneN[];

    const caTimezones = [
        "UTC",
        "Canada/Atlantic",
        "Canada/Eastern",
        "Canada/Central",
        "Canada/Mountain",
        "Canada/Pacific",
    ] as TimezoneN[];

    // What I really want to know here is "Which Everlaw instance are we on,"
    // but "Which AWS region am I in" is good enough. Actually, we only have an
    // instance in a handful of these regions, but others are included here for
    // futureproofing.
    switch (JSP_PARAMS.Server.region) {
        case AWSRegion.AP_SOUTHEAST_2_SYDNEY:
            return auTimezones;

        case AWSRegion.EU_CENTRAL_1_FRANKFURT:
        case AWSRegion.EU_WEST_2_LONDON:
            return euTimezones;

        case AWSRegion.US_EAST_1_N_VIRGINIA:
        case AWSRegion.US_WEST_1_N_CALIFORNIA:
        case AWSRegion.US_WEST_2_OREGON:
            return usTimezones;

        case AWSRegion.CA_CENTRAL_1:
            return caTimezones;

        // If we expand to other regions, we should probably add more cases
        // above but this is a reasonable default.
        default:
            return usTimezones;
    }
}
