import Base = require("Everlaw/Base");
import BaseSelector = require("Everlaw/UI/BaseSelector");
import Button = require("Everlaw/UI/Button");
import ColorUtil = require("Everlaw/ColorUtil");
import { EverColor } from "design-system";
import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import { Arr, Is } from "core";
import Tooltip = require("Everlaw/UI/Tooltip");
import UI = require("Everlaw/UI");

/**
 * Background colors that need dark text/icons rather than white text/icons.
 * We assume no background color (transparent/default backgrounds) work well with
 * dark text as well.
 */
const needDarkForegroundColors = [EverColor.YELLOW_20, EverColor.YELLOW_30].map((c) =>
    c.toLowerCase(),
);
function needsDarkForeground(color: string) {
    return Arr.contains(needDarkForegroundColors, color.toLowerCase());
}

interface RadioOption {
    button: Button;
    buttonWrapper: HTMLElement;
    icon?: string;
    hoverIcon?: string;
}

/**
 * The functionality of BasicRadio, but using buttons instead of browser circle/dot.
 * Only one option may be selected, but nothing needs to be selected initially.
 */
class Radio<T extends Base.Object> extends BaseSelector<T> {
    elements: T[];
    options: RadioOption[];
    /** Called only when the value changes. */
    onChange(current: T, previous: T | null) {}
    /** Called if any value is selected, even the already selected one. */
    onSelect(current: T, previous: T | null) {}
    constructor(params: Radio.Params<T>) {
        super(Dom.div({ class: ["radio-group", params.selectorClass].join(" ").trim() }));
        this.elements = params.elements;
        if (params.onChange) {
            this.onChange = params.onChange;
        }
        const disabledIds = new Set<string | number>();
        if (params.disabled) {
            params.disabled.elements.forEach((el) => disabledIds.add(el.id));
        }
        const buttonContainer = Dom.create(
            "div",
            {
                class: "radio-group-button-container",
                role: "radiogroup",
            },
            this,
        );
        if (params.label) {
            const labelId = `radio-group-${this.id}`;
            const label = Dom.label(
                {
                    id: labelId,
                    class: "radio-group-label",
                },
                params.label,
            );
            Dom.place(label, buttonContainer, "before");
            Dom.setAttr(buttonContainer, "aria-labelledby", labelId);
            Dom.addClass(buttonContainer, "labeled");
        }

        this.options = this.elements.map((elem, ind) => {
            const icon = params.icon ? params.icon(elem) : undefined;
            let message = params.tooltip && params.tooltip(elem);
            const alt = message || undefined;
            // Wrap the button in a div so disabled tooltips can be shown.
            const buttonWrapper = Dom.create(
                "div",
                { class: "radio-group-button-wrapper" },
                buttonContainer,
            );
            let clazz = "toggle";
            if (ind === 0) {
                clazz += " first";
            }
            if (ind === this.elements.length - 1) {
                clazz += " last";
            }
            const btn = new Button({
                label: params.getContent ? params.getContent(elem) : this.display(elem),
                parent: buttonWrapper,
                class: clazz,
                width: Is.defined(params.width) ? params.width : "one",
                icon,
                alt,
                makeFocusable: params.makeFocusable,
                focusStyling: params.focusStyling,
            });
            const disabled = disabledIds.has(elem.id);
            let tooltipNode = buttonWrapper;
            if (disabled) {
                // Disable buttons don't fire normal events like mouseenter and mouseleave. Attach
                // the tooltip to a node that covers the button.
                tooltipNode = Dom.create(
                    "div",
                    {
                        class: "radio-group-tooltip-proxy",
                    },
                    buttonWrapper,
                );
                btn.setDisabled(true);
                message = Is.func(params.disabled?.tooltip)
                    ? params.disabled.tooltip(elem)
                    : params.disabled?.tooltip || "";
            }
            if (message) {
                const msgDiv = Dom.div({ style: { width: "auto", maxWidth: "352px" } }, message);
                this.registerDestroyable(new Tooltip(tooltipNode, msgDiv));
            }
            Dom.setAttr(btn, "role", "radio");
            const hoverIcon =
                (params.hoverIcon && params.hoverIcon(elem)) || (icon && icon + "-white");
            if (icon || params.onHoverChange) {
                this.connect(btn.node, "mouseenter", () => {
                    const selected = this.getSelected();
                    if (!selected || !selected.equals(elem)) {
                        icon && Dom.replaceClass(btn.icon, `icon_${hoverIcon}`, `icon_${icon}`);
                        params.onHoverChange && params.onHoverChange(elem, true);
                    }
                });
                this.connect(btn.node, "mouseleave", () => {
                    const selected = this.getSelected();
                    if (!selected || !selected.equals(elem)) {
                        icon && Dom.replaceClass(btn.icon, `icon_${icon}`, `icon_${hoverIcon}`);
                        params.onHoverChange && params.onHoverChange(elem, false);
                    }
                });
                if (params.makeFocusable && btn.focusDiv) {
                    this.connect(btn.focusDiv.node, "focus", () => {
                        const selected = this.getSelected();
                        if (
                            (!selected || !selected.equals(elem))
                            && !Dom.hasAttr(btn.node, "disabled")
                        ) {
                            icon && Dom.replaceClass(btn.icon, `icon_${hoverIcon}`, `icon_${icon}`);
                            params.onHoverChange && params.onHoverChange(elem, true);
                        }
                    });
                    this.connect(btn.focusDiv.node, "blur", () => {
                        const selected = this.getSelected();
                        if (
                            (!selected || !selected.equals(elem))
                            && !Dom.hasAttr(btn.node, "disabled")
                        ) {
                            icon && Dom.replaceClass(btn.icon, `icon_${icon}`, `icon_${hoverIcon}`);
                            params.onHoverChange && params.onHoverChange(elem, false);
                        }
                    });
                }
            }
            if (params.class) {
                Dom.addClass(btn, params.class);
            }
            // onmousedown instead of onclick because the latter actually triggers
            // the blur first, and does NOT set the focus on the new element in
            // Chrome. Thus, onBlur is invoked before the click happens. So
            // instead we hijack the action as soon as the mouse button is
            // depressed.
            this.connect(btn.node, Input.press, () => {
                this.select(elem);
            });
            // Due to the above comment re: "onmousedown" (Input.press) instead of "onclick" (Input.tap)
            // this empty callback is needed in order to to prevent the "tap" from bubbling up, to fix the following:
            // In the STR page there was a problem where clicking on a radio caused a resize and a scroll,
            // and the mouseup event was fired on the container, which then (using delegation) imagined
            // the user clicked on a cell that happened to move to where the radio WAS before the scroll.
            // So TL/DR - prevent the tap since we accept the press.
            this.connect(btn.node, Input.tap, () => {});

            // also trap Enter from the keyboard
            UI.onSubmit(btn.node, () => {
                this.select(elem);
            });
            if (params.makeFocusable && btn.focusDiv) {
                this.registerDestroyable(
                    Input.fireCallbackOnKey(btn.focusDiv.node, [Input.ENTER], () =>
                        this.select(elem),
                    ),
                );
            }
            return {
                button: btn,
                buttonWrapper,
                icon,
                hoverIcon,
            };
        });
        this.resetBorders();
    }
    override focus() {
        if (this._selected) {
            this.options[this._selected.findIn(this.elements)].button.focus();
        } else if (this.options.length > 0) {
            this.options[0].button.focus();
        }
    }
    clearSelection() {
        if (this._selected) {
            this._unselect(this.options[this._selected.findIn(this.elements)], this._selected);
            this._selected = null;
        }
    }
    select(elem: T, silent = false) {
        const which = elem.findIn(this.elements);
        if (which >= 0) {
            const was = this._selected;
            if (was) {
                this._unselect(this.options[was.findIn(this.elements)], was);
            }
            this._select(this.options[which], elem);
            this._selected = elem;
            if (!silent) {
                if (!elem.equals(was)) {
                    this.onChange(elem, was);
                }
                this.onSelect(elem, was);
            }
        }
    }
    override getValue(): T {
        return <T>super.getValue();
    }
    /**
     * Resets borders back to the default state (where all buttons are either enabled or disabled).
     * All buttons have a right border but only the leftmost button has a left border.
     */
    resetBorders() {
        for (let i = this.options.length - 1; i >= 1; i--) {
            const nextButtonEnabled = !this.options[i - 1].button.node.disabled;
            Dom.toggleClass(this.options[i].button, "no-left-border", nextButtonEnabled);
        }
    }
    setDisabled(state: boolean, elem?: T) {
        if (!elem) {
            this.options.forEach((option) => {
                UI.toggleDisabled(option.button, state);
            });
            this.resetBorders();
        } else if (elem !== this._selected) {
            const which = elem.findIn(this.elements);
            if (which >= 0) {
                UI.toggleDisabled(this.options[which].button, state);
                // When an element is disabled, the border it shares with its left neighbor is
                // affected by the opacity change and doesn't show up well. The somewhat hacky
                // solution is to have its neighbor display its right border. This does cause a
                // one-pixel shift in the content, but I don't have a better solution right now.
                if (which >= 1) {
                    Dom.toggleClass(this.options[which - 1].button, "no-right-border", !state);
                }
            }
        }
    }
    clear() {
        if (this._selected) {
            this._unselect(this.options[this._selected.findIn(this.elements)], this._selected);
            this._selected = null;
        }
    }
    private _select(option: RadioOption, elem: T) {
        const button = option.button;
        Dom.addClass(button, "selected");
        if (button.icon) {
            const replaced =
                `icon_${option.icon}` + (option.hoverIcon ? ` icon_${option.hoverIcon}` : "");
            Dom.replaceClass(button.icon, `icon_${this.selectedIcon(option, elem)}`, replaced);
        }
        const color = ColorUtil.colorAsHex(elem);
        Dom.toggleClass(button, "dark-foreground", needsDarkForeground(color));
        Dom.style(button, { background: color, borderColor: color });
        Dom.setAttr(button, "aria-checked", "true");
    }
    private _unselect(option: RadioOption, elem: T) {
        const button = option.button;
        Dom.removeClass(button, "selected");
        if (button.icon) {
            Dom.replaceClass(
                button.icon,
                `icon_${option.icon}`,
                `icon_${this.selectedIcon(option, elem)}`,
            );
        }
        Dom.removeClass(button, "dark-foreground");
        Dom.style(button, { background: "", borderColor: "", color: "" });
        Dom.setAttr(button, "aria-checked", "false");
    }
    private selectedIcon(option: RadioOption, elem: T) {
        return needsDarkForeground(ColorUtil.colorAsHex(elem)) ? option.icon : option.hoverIcon;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module Radio {
    export interface Params<T> {
        elements: T[];
        disabled?: {
            elements: T[];
            tooltip: string | ((elem: T) => string);
        };
        class?: string;
        width?: string | null;
        icon?: (obj: T) => string;
        /**
         * Icon to use when a button is hovered. Defaults to icon + "_white".
         * If your hover icon is white rename it to use the "_white" convention if it doesn't already
         * rather than specifying an inconsistent name here!
         */
        hoverIcon?: (obj: T) => string;
        // Label text above the radio widget.
        label?: string;
        onChange?: (current: T, previous: T) => void;
        /**
         * In case any additional action is needed on mouse enter/leave, i.e. something more signifcant
         * than just an icon swap. Note that this is only called when the mouse enter/leave state
         * changes *and* the element is not the currently active element (otherwise no state change
         * should be necessary).
         */
        onHoverChange?: (obj: T, enter: boolean) => void;
        getContent?: (obj: T) => Dom.Content;
        tooltip?: (obj: T) => string;
        makeFocusable?: boolean;
        focusStyling?: string[] | string;
        /**
         * Custom style class for Radio node. This does not replace the default styling.
         */
        selectorClass?: string;
    }

    export interface IconRadioParams<T> {
        elements: T[];
        icon: (obj: T) => string;
        hoverIcon?: (obj: T) => string;
        tooltip?: (obj: T) => string;
        makeFocusable?: boolean;
        focusStyling?: string | string[];
    }

    /**
     * Like Radio, but styled to only contain 24x24 icons.
     */
    export class IconRadio<T extends Base.Object> extends Radio<T> {
        constructor(params: IconRadioParams<T>) {
            super({
                elements: params.elements,
                icon: params.icon,
                hoverIcon: params.hoverIcon,
                tooltip: params.tooltip,
                width: null,
                getContent: () => null,
                makeFocusable: params.makeFocusable,
                focusStyling: params.focusStyling,
            });
            Dom.addClass(this, "icons-only");
        }
    }
}

export = Radio;
